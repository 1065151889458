const hymns = [
  {
    id: 0,
    title: "La alegría",
    lyrics: "<p>La alegría está en el corazón</p>\n<p>De quien ya conoce a Jesús</p>\n<p>La verdadera paz está en aquel</p>\n<p>Que ya conoce a Jesús.</p>\n<h3>Coro</h3>\n<p>El sentimiento más precioso</p>\n<p>Que viene de nuestro Señor</p>\n<p>Es el amor que solo tiene</p>\n<p>Quien conoce a Jesús</p>\n<p>¡Aleluya! ¡Amén!</p>\n<p><br></p>\n<p>Puedes cruzar los montes</p>\n<p>Y escalar las montañas</p>\n<p>¡Aleluya! ¡Aleluya!</p>\n<p>Puedes cruzar los montes</p>\n<p>Y escalar las montañas</p>\n<p>¡Aleluya! ¡Aleluya!</p>"
  },
  {
    id: 1,
    title: "Id con Cristo",
    lyrics: "<p>Id con Cristo caminando sobre el agua</p>\n<p>Tú lo podrás si te dejas llevar.</p>\n<p>Puedes ir con Cristo la tormenta puede Él calmar</p>\n<p>Id con cristo te salvará</p>\n<p>\n    <br>\n</p>\n<p>Si te sientes desdichado</p>\n<p>Con profundo vacío</p>\n<p>Solo en Cristo hallarás</p>\n<p>El Camino de Vida</p>\n<p>Acéptale hoy en tu corazón</p>\n<p>Y verás entonces tu vida cambiar.</p>"
  },
  {
    id: 2,
    title: "Puedes confiar en el Señor",
    lyrics: "<p>Puedes confiar en el Señor</p>\n<p>Que Él te va a cuidar</p>\n<p>Puedes confiar en el Señor</p>\n<p>Que Él te va a cuidar</p>\n<h3>Coro</h3>\n<p>Si el sol llegara a obscurecer</p>\n<p>Y no brilla más</p>\n<p>Igual confía en el Señor</p>\n<p>Que Él te va a cuidar</p>\n<p>\n    <br>\n</p>\n<p>Puedes descansar</p>\n<p>Puedes descansar</p>\n<p>Que una mansión</p>\n<p>Cristo te dará</p>"
  },
  {
    id: 3,
    title: "Dios está aquí",
    lyrics: "<p>Dios está aquí</p>\n<p>Es tan cierto como el aire que respiro</p>\n<p>Es tan cierto como la mañana se levanta el sol</p>\n<p>Es tan cierto como que le canto y me puede oír.</p>\n<p>\n    <br>\n</p>\n<p>Lo puedes sentir en este mismo</p>\n<p>Lo puedes sentir muy cerca de tu corazón</p>\n<p>Te puede ayudar en ese problema que tienes</p>\n<p>Jesús está aquí, si tu quieres lo puedes sentir.</p>"
  },
  {
    id: 4,
    title: "Sobre Todo",
    lyrics: "<p>Sobre la fuerza de todo rey</p>\n<p>Sobre natura y toda creación</p>\n<p>Aun mas sabio que todo humano aquí</p>\n<p>Fuiste antes de la creación.</p>\n<p>Y sobre reino y tronos mil</p>\n<p>Y maravillas aun sin descubrir</p>\n<p>Sobre riquezas y tesoro terrenal</p>\n<p>Nada es imposible para Ti</p>\n<h3>Coro</h3>\n<p>Al morir solo en esa cruz</p>\n<p>Me diste así razón para vivir</p>\n<p>Siendo Tú, el Hijo del gran Rey </p>\n<p>Moriste así, pensando en mi Más que en Ti.</p>"
  },
  {
    id: 5,
    title: "El poder de tu amor",
    lyrics: "<p>Dios me rindo a Ti cambia hoy mi corazón</p>\n<p>Renuévame Señor con tu tierno amor</p>\n<p>Yo reconozco que debilidades hay en mi ser</p>\n<p>Pues todo cambiará con el Poder de tu Amor.</p>\n<h3>Coro</h3>\n<p>Abrázame que tu amor me cubra</p>\n<p>Junto a Ti quiero siempre estar.</p>\n<p>Y al despertar levantaré mis alas</p>\n<p>El vuelo emprenderé y tú me guiarás</p>\n<p>Con el Poder de tu Amor.</p>\n<p>\n    <br>\n</p>\n<p>Cada día más quiero conocer de tu amor</p>\n<p>De tu Espíritu lléname, Señor.</p>\n<p>Y renuévame haz tu voluntad en mi ser</p>\n<p>Dirígeme, Señor, por el poder de tu amor</p>"
  },
  {
    id: 6,
    title: "Tu eres mi respirar",
    lyrics: "<p>Tu eres mi respirar</p>\n<p>Tu eres mi respirar</p>\n<p>Dios tu presencia vive en mi Eres mi pan Señor</p>\n<p>Eres mi pan Señor</p>\n<p>Dios tu palabra vive en mi</p>\n<h3>Coro</h3>\n<p>Y yo te anhelo Señor</p>\n<p>Y estoy perdido sin Ti</p>"
  },
  {
    id: 7,
    title: "Dios tu nombre exaltaré",
    lyrics: "<p>Dios tu Nombre exaltaré</p>\n<p>Y yo canto tus bondades</p>\n<p>Yo Contigo soy feliz</p>\n<p>Te doy gracias por salvarme.</p>\n<h3>Coro</h3>\n<p>Del cielo viniste Tú a Redimir</p>\n<p>Desde la tierra a la cruz a perdonar</p>\n<p>De la cruz a morir, de morir a la Luz</p>\n<p>Dios tu Nombre exaltaré.</p>"
  },
  {
    id: 8,
    title: "Vine a adorarte",
    lyrics: "<p>Tu eres la luz que brilló en las tinieblas</p>\n<p>Abrió mis ojos pude ver.</p>\n<p>Mi corazón adora tu hermosura</p>\n<p>Esperanza de vida eres Tú.</p>\n<h3>Coro</h3>\n<p>Vine a adorarte vine a postrarme</p>\n<p>Vine a decir que eres mi Dios.</p>\n<p>Sólo tu eres santo, solo tu eres digno</p>\n<p>Eres asombroso para mi.</p>\n<p>\n    <br>\n</p>\n<p>Tu eres el rey grandemente exaltado</p>\n<p>Glorioso por siempre Señor.</p>\n<p>El mundo tu creaste y humilde viniste</p>\n<p>Pobre te hiciste por amor.</p>\n<p>\n    <br>\n</p>\n<h3>Puente</h3>\n<p>Jamás sabré cuánto costó</p>\n<p>Ver mi maldad sobre esa cruz.</p>\n<p>Jamás sabré cuánto costó</p>\n<p>Ver mi maldad sobre esa cruz.</p>\n"
  },
  {
    id: 9,
    title: "Cante al Señor",
    lyrics: "<p>Mi cristo, mi rey nadie es como Tú</p>\n<p>Toda mi vida quiero exaltar las maravillas de tu amor.</p>\n<p>Consuelo, refugio, torre de fuerza y poder</p>\n<p>Todo mi ser, lo que yo soy nunca deje de adorar.</p>\n<h3>Coro</h3>\n<p>Cante al Señor toda la creación</p>\n<p>Honra, poder, majestad sea al rey</p>\n<p>Montes caerán y el mar rugirá al sonar</p>\n<p>De tu nombre</p>\n<p>Canto con gozo al mirar tu poder</p><p> Por siempre yo te amaré y diré:</p>\n<p>Incomparables promesas me das Señor.</p>"
  },
  {
    id: 10,
    title: "Guíame Señor",
    lyrics: "<p>Guíame Señor, no me dejes ir</p>\n<p>Yo quiero regresar a Ti</p>\n<p>Y oír tu tierna voz decir</p>\n<p>Tu amigo soy eso anhelo hoy</p>\n<p>Pues nadie más me puede dar</p>\n<p>El dulce amor que tu me das</p>\n<p>Guíame Señor quiero regresar</p>\n<h3>Coro</h3>\n<p>Volverte a amar</p>\n<p>Volver a mi hogar</p>\n<p>Volverte a amar</p>\n<p>Como me amas Tú.</p>"
  },
  {
    id: 11,
    title: "Se trata de Ti",
    lyrics: "<p>Melodías van y vienen sin cesar Y hoy estoy aquí.</p>\n<p>Mi deseo es traerte lo mejor un incienso a Ti.</p>\n<h3>Pre-coro</h3>\n<p>Más que una dulce canción yo te traigo Señor</p>\n<p>Tu me conoces muy bien no solo las apariencias Sino dentro de mi Tú miras mi corazón.</p>\n<h3>Coro</h3>\n<p>Y vengo hoy a adorarte mi Cristo</p>\n<p>Pues se trata de Ti, tan solo de Ti Jesús Perdona si olvide el motivo</p>\n<p>Pues se trata de Ti, tan sólo de Ti Jesús.</p>\n<p>\n    <br>\n</p>\n<p>Mi eterno Rey,</p>\n<p>¿cómo expresar lo que eres Tú?</p>\n<p>Todo lo que soy te lo debo a Ti</p>\n<p>Y por eso hoy</p>"
  },
  {
    id: 12,
    title: "Eres mi todo",
    lyrics: "<p>Eres mi fuerza y mi poder</p>\n<p>El gran tesoro que busqué</p>\n<p>Eres mi todo Dios.</p>\n<p>Perla de precio sin igual</p>\n<p>Nunca tu amor podré dejar</p>\n<p>Eres mi todo Dios</p>\n<h3>Coro</h3>\n<p>Cristo, redentor</p>\n<p>Digno de alabar</p>\n<p>Cristo, salvador</p>\n<p>Digno de adorar</p>\n<p>\n    <br>\n</p>\n<p>En sequedad o en tentación</p>\n<p>Tú me sostienes mi Señor</p>\n<p>Eres mi todo Dios</p>\n<p>Toma mi vida mi Señor</p>\n<p>Yo te la ofrezco Salvador Porque te amo Dios.</p>"
  },
  {
    id: 13,
    title: "Agnus Dei",
    lyrics: "<p>Aleluya, Aleluya</p>\n<p>Te adoramos Señor nuestro Rey</p>\n<p>Aleluya, Aleluya</p>\n<p>Te adoramos Señor nuestro Rey</p>\n<p>Aleluya</p>\n<h3>Coro</h3>\n<p>Santo, Santo, Tu eres Santo Mi Salvador</p>\n<p>Digno eres Tú, Digno eres Tú Amén.</p>"
  },
  {
    id: 14,
    title: "Cantaré de tu amor por siempre",
    lyrics: "<p>Sobre los montes y el mar, el río corre con tu amor, Y abriré mi corazón y gozaré tu libertad.</p>\n<p>Feliz me siento oh Señor, pues sé que a tu lado estoy,</p>\n<p>Levanto hoy mi voz y cantaré de tu amor.</p>\n<h3>Coro</h3>\n<p>Cantaré de tu amor por siempre.</p>\n<p>Cantaré de tu amor por siempre.</p>\n<p>Cantaré de tu amor por siempre.</p>\n<p>Cantaré de tu amor por siempre.</p>"
  },
  {
    id: 15,
    title: "Tu Poder",
    lyrics: "<p>Queremos hoy pedir señor, tu poder, tu poder</p>\n<p>No hay otra forma de avanzar tu poder, tu poder</p>\n<h3>Coro</h3>\n<p>Con tu poder nos haremos fuertes</p>\n<p>Con tu poder vamos a vencer</p>\n<p>En tu poder está nuestra esperanza</p>\n<p>Envía tu poder</p>\n<p>\n    <br>\n</p>\n<p>Estamos tan cansados ya, oh Señor, Danos poder</p>\n<p>Renueva nuestro corazón, con poder, danos poder</p>"
  },
  {
    id: 16,
    title: "Este es mi deseo",
    lyrics: "<p>Este es mi deseo Honrarte a Ti.</p>\n<p>Con todo mi ser Te adoro a Ti.</p>\n<p>Con todas mis fuerzas Te alabaré.</p>\n<p>Mi adoración eres Tú.</p>\n<h3>Coro</h3>\n<p>Hoy te rindo mi ser</p>\n<p>Te doy mi corazón.</p>\n<p>Yo vivo para Ti.</p>\n<p>En cada palpitar</p>\n<p>Mientras haya aliento en mí</p>\n<p>Dios haz Tu obra en mí.</p>"
  },
  {
    id: 0,
    title: "",
    lyrics: ""
  },
  {
    id: 17,
    title: "Fija tus ojos en Cristo",
    lyrics: "<p>Fija tus ojos en Cristo</p>\n<p>Tan lleno de gracia y amor</p>\n<p>Y lo terrenal sin valor será</p>\n<p>A la luz del glorioso Señor</p>"
  },
  {
    id: 18,
    title: "Abre mis ojos oh Cristo",
    lyrics: "<p>Abre mis ojos, oh, Cristo</p>\n<p>Abre mis ojos, te pido</p>\n<p>Yo quiero verte</p>\n<p>Yo quiero verte</p>\n<h3>Coro</h3>\n<p>Y contemplar tu majestad</p>\n<p>Y el resplandor de tu gloria</p>\n<p>Derrama tu amor y poder</p>\n<p>Cuando cantamos \"santo, santo\"</p>\n<h3>Puente</h3>\n<p>Santo, santo, santo</p>\n<p>Santo, santo, santo</p>\n<p>Santo, santo, santo</p>\n<p>Yo quiero verte</p>"
  },
  {
    id: 19,
    title: "Jesuscristo Basta",
    lyrics: "<p>Nuestros corazones</p>\n<p>Insaciables son</p>\n<p>Hasta que conocen</p>\n<p>A su salvador</p>\n<p>Tal y como somos, nos amó</p>\n<p>Hoy, nos acercamos sin temor</p>\n<h3>Coro</h3>\n<p>Él es el agua que al beber</p>\n<p>Nunca más tendremos sed</p>\n<p>Jesucristo basta</p>\n<p>Jesucristo basta</p>\n<p>Mi castigo recibió</p>\n<p>Y Su herencia me entregó</p>\n<p>Jesucristo basta</p>\n<p>Jesucristo basta</p>\n<p>\n    <br>\n</p>\n<p>Fuimos alcanzados</p>\n<p>Por Su gran amor</p>\n<p>Con brazos abiertos</p>\n<p>Nos recibe hoy</p>\n<p>Tal y como somos, nos amó</p>\n<p>Hoy, nos acercamos sin temor</p>\n<h3>Puente</h3>\n<p>Ahora hay un futuro</p>\n<p>Y esperanza fiel</p>\n<p>En Su amor, confiamos</p>\n<p>Hay descanso en Él</p>\n<p>\n    <br>\n</p>"
  },
  {
    id: 20,
    title: "Vasijas rotas",
    lyrics: "<p>Mi alma estaba rota y herida</p>\n<p>Pero, tu gracia la restauró</p>\n<p>Manos vacías que tú llenaste</p>\n<p>Soy libre en ti, soy libre en ti</p>\n<h3>Coro</h3>\n<p>Sublime gracia del Señor</p>\n<p>Que a un pecador salvó</p>\n<p>Fui ciego, mas, hoy veo yo</p>\n<p>Perdido y él me halló</p>\n<p>Ahora puedo ver</p>\n<p>Oh, puedo ver sus ojos de amor</p>\n<p>Quebrantado fue</p>\n<p>Para darnos su salvación</p>\n<p>\n    <br>\n</p>\n<p>Tú no me juzgas por mis fracasos</p>\n<p>Tú me aceptas tal como soy</p>\n<p>Toma mi vida como vasija</p>\n<p>Para mostrar tu gloria en mí</p>"
  },
  {
    id: 21,
    title: "Digno y Santo",
    lyrics: "<p>Digno y Santo el cordero</p>\n<p>Inmolado en la cruz</p>\n<p>Nuevo canto levantaremos</p>\n<p>Al que en su trono está</p>\n<h3>Coro</h3>\n<p>Santo, santo, santo</p>\n<p>Dios todo poderoso</p>\n<p>Quien fue, quien es y quien vendrá</p>\n<p>La creación te canta</p>\n<p>Hosanna al gran Yo soy</p>\n<p>Tú eres mi todo</p>\n<p>Y yo te adoraré</p>\n<p>\n    <br>\n</p>\n<p>De un arco iris, está vestido</p>\n<p>Su voz resuena, como los truenos</p>\n<p>Recibe honor y gloria</p>\n<p>Poder y majestad</p>\n<p>A ti, el único Rey</p>\n<p>\n    <br>\n</p>\n<p>Tan grandioso, asombroso</p>\n<p>Con solo decir Jesús</p>\n<p>Cristo tu nombre es grande</p>\n<p>Fuerte inagotable</p>\n<p>Tu misterio glorioso es</p>"
  }
]

export default hymns;
